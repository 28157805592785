<template>
  <Header title="Ocenenia" />
  <div id="awards" class="container">

    <div class="row plaque-stats current-plaque">
      <div class="col-12">
        <span class="item-list-heading mt-3">Aktuálny nárok na ocenenie:</span>
      </div>
      <div class="align-self-center" :class="currentPlaque === null ? 'col-12' : 'col-9'">
        <span v-if="currentPlaque !== null"><b>{{currentPlaque?.name}}</b><br></span>
        <span v-if="currentPlaque === null"><b>Zatiaľ nemáte nárok na plaketu</b><br></span>
        <span>Počet odberov: <b>{{ pocetOdberov || '-' }}</b></span>
      </div>
      <div class="col-3 plaque-img text-center" v-if="currentPlaque !== null">
        <img :src="require('@/assets/' + currentPlaque?.img)" v-if="currentPlaque !== null"  :alt="currentPlaque?.name"/>
      </div>
    </div>

    <div class="row plaque-stats next-plaque" v-if="nextPlaque !== null">
      <div class="col-12">
        <span class="item-list-heading">Nasledujúce ocenenie:</span>
      </div>
      <div class="col-9 align-self-center">
        <span><b>{{ nextPlaque?.name || '-'}}</b></span><br>
        <span>Pocet zostávajúcich odberov: <b>{{ donationsLeft || '-'}}</b></span>
      </div>
      <div class="col-3 plaque-img text-center">
        <img :src="require('@/assets/' + nextPlaque?.img)" v-if="nextPlaque !== null"  :alt="nextPlaque?.name"/>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <span class="item-list-heading">Klasifikácia ocenení:</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 plaque-table">
        <div class="row table-header">
          <div class="cell col-6"><span>Plaketa</span></div>
          <div class="cell col-3"><span>Odbery Muži</span></div>
          <div class="cell col-3"><span>Odbery Ženy</span></div>
        </div>
        <div class="row" v-for="(item, index) in plaques" v-bind:key="index">
          <div class="cell col-6">{{item.name}}</div>
          <div class="cell col-3">{{item.muzi}}</div>
          <div class="cell col-3">{{item.zeny}}</div>
        </div>
      </div>
    </div>
    <pre class="d-none">{{userData}}</pre>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import Header from '@/components/Header'

export default {
  name: 'Awards',
  components: {
    Header
  },
  data () {
    return {
      plaques: [
        { name: 'Bronzová plaketa', bent: 'Bronzovú plaketu', img: 'bronze_plaque.png', muzi: 10, zeny: 10 },
        { name: 'Strieborná plaketa', bent: 'Striebornú plaketu', img: 'silver_plaque.png', muzi: 20, zeny: 20 },
        { name: 'Zlatá plaketa', bent: 'Zlatú plaketu', img: 'gold_plaque.png', muzi: 40, zeny: 30 },
        { name: 'Diamantová plaketa', bent: 'Diamantovú plaketu', img: 'diamond_plaque.png', muzi: 80, zeny: 60 },
        { name: 'Kňazovického medaila', bent: 'Kňazovického medailu', img: 'knazovicky_metal.png', muzi: 100, zeny: 80 }
      ]
    }
  },
  beforeMount () {
    this.fetchData()
  },
  watch: {
    '$store.state.isLoggedIn': function () {
      this.fetchData()
    }
  },
  computed: {
    ...mapState([
      'isLoggedIn',
      'userSex',
      'userData'
    ]),
    pocetOdberov () {
      return this.userData?.PocetOdberov || '-'
    },
    /* pocetOdberovWord (pocet) {
      return pocet === 0 || pocet > 4 ? 'odberov' : (pocet > 1 ? 'odbery' : 'odber')
    }, */
    currentPlaque () {
      let plaque = null
      const donations = Number(this.pocetOdberov)
      for (let i = 0; i < this.plaques.length; i++) {
        if (this.plaques[i][(this.userSex === 'M' ? 'muzi' : 'zeny')] <= donations) {
          plaque = this.plaques[i]
        }
      }
      return plaque
    },
    nextPlaque () {
      let plaque = null
      const donations = Number(this.pocetOdberov)
      for (let i = 0; i < this.plaques.length; i++) {
        if (this.plaques[i][(this.userSex === 'M' ? 'muzi' : 'zeny')] > donations) {
          plaque = this.plaques[i]
          break
        }
      }
      return plaque
    },
    donationsLeft () {
      const donations = Number(this.pocetOdberov)
      return this.nextPlaque?.[(this.userSex === 'M' ? 'muzi' : 'zeny')] - donations
    }
  },
  methods: {
    fetchData () {
      if (this.isLoggedIn) {
        this.userSex === null && this.$store.dispatch('fetchUserHome')
        this.userData === null && this.$store.dispatch('fetchUserData')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#awards {
  text-align: left;
  .plaque-img img {
    max-height: 150px;
    max-width: 62.7px;
    width: 100%;
  }
  .item-list-heading {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .plaque-table {
    padding: 0 calc(var(--bs-gutter-x));
    text-align: center;
    overflow-y: scroll;
    overflow-x: hidden;
    h3 {
      font-size: 20px;
    }
    .table-header {
      font-weight: bold;
    }
    .col-6 {
      align-self: flex-end;
      text-align: left;
    }
    .row {
      border: solid var(--border-color-secondary);
      border-width: 0 1px 1px 1px;
      &.table-header {
        border-top-width: 1px;
      }
      .cell {
        border: solid var(--border-color-secondary);
        border-width: 0 0 0 1px;
        &:first-child {
          border-width: 0;
        }
      }
    }
  }
}
</style>
